import * as React from 'react';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {graphql} from 'gatsby';
import {Accordion, AccordionDetails, AccordionSummary, Grid} from "@material-ui/core";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Layout} from "../common/Layout";
import {SEO} from "../components/seo";
import {Heading} from "../components/heading";

// Query to be run on build, passes resulting JSON as 'data' prop
export const query = graphql`
  {
    allDirectusHelp(
        sort: {
            fields: [sort]
            order: ASC
        }) {
        nodes {
          body
          directusId
          modified_by
          modified_on
          sort
          status
          title
          url_title
        }
    }
  }
`;

const Help = ({ data }) => {
    return (
        <Layout>
            <SEO description={``} keywords={[`help`, `ccg`, `collectible`, `card`, `game`, `trade`]} title={`CCG Trader Help`} />
            <section className="">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Heading text="Help" />
                    </Grid>
                    <Grid item xs={12}>
                        {data.allDirectusHelp.nodes.map(help =>
                            <Accordion key={help.directusId}>
                                <AccordionSummary
                                    aria-controls={`panel${help.directusId}-content`}
                                    expandIcon={<ExpandMoreIcon />}
                                    id={`panel${help.directusId}-header`}
                                >
                                    {help.title}
                                </AccordionSummary>
                                <AccordionDetails id={`panel${help.directusId}-content`}>
                                    <ReactMarkdown children={help.body} className="markdown" remarkPlugins={[remarkGfm]} />
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </Grid>
                </Grid>
            </section>
        </Layout>
    );
};

export default Help;
